import React from 'react';

import Header from './Layout/Header';

function Layout() {
  return (
		<Header />
  );
}

export default Layout;
