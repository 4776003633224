import './App.css';
import React from 'react';
import axios from 'axios';

import Layout from './Layout';

function App() {
  axios.defaults.baseURL = 'https://clangermanagement.azurewebsites.net/api/';
  axios.defaults.headers.common['Authorization'] = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiIsImtpZCI6IjI4YTMxOGY3LTAwMDAtYTFlYi03ZmExLTJjNzQzM2M2Y2NhNSJ9.eyJpc3MiOiJzdXBlcmNlbGwiLCJhdWQiOiJzdXBlcmNlbGw6Z2FtZWFwaSIsImp0aSI6ImUzMDJhMDIwLTQyYWMtNDEzNC1hMDY0LWJmYzYyZWQ4ZDgwZCIsImlhdCI6MTYwNjYzNDA3MSwic3ViIjoiZGV2ZWxvcGVyLzM0NGExNjM5LTYwMGEtMzNkYS0yMzc3LWFiMThkNzdmYzk3NyIsInNjb3BlcyI6WyJyb3lhbGUiXSwibGltaXRzIjpbeyJ0aWVyIjoiZGV2ZWxvcGVyL3NpbHZlciIsInR5cGUiOiJ0aHJvdHRsaW5nIn0seyJjaWRycyI6WyI1Mi4xODguMjE2LjE0MiJdLCJ0eXBlIjoiY2xpZW50In1dfQ.jFqaU7N_NCMTtF5T7m4hqy6gPAHVzsxFsXXCkY1K163SZnaSrAfHhxm8QAtsI5htMcZgJhCcJOoERGfUWFHHDA';
  
  return (
    <Layout />
  );
}

export default App;
